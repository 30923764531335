import React, { useContext } from 'react';

import { StoreContext } from 'context/StoreContext';
import { CartDiscount } from '../CartDiscount';

import styles from './CartSubtotalBox.module.css';

export const CartSubtotalBox: React.FC = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  const handleCheckout = () => {
    // Sends users to complete checkout in Shopify
    // Upon completion, users will be redirected to /store/thank-you
    window.location.replace(checkout.webUrl);
  };

  const { subtotalPrice, lineItems } = checkout;

  return (
    <>
      <h2 className={styles.subtitle}>
        Subtotal: <span className={styles.subtotalPrice}>${subtotalPrice}</span>
      </h2>

      <CartDiscount />

      <button
        className={styles.checkoutBtn}
        disabled={lineItems.length === 0}
        onClick={handleCheckout}
        type="button"
      >
        Check out
      </button>
    </>
  );
};
