import React, { useContext } from 'react';
import { Link } from 'gatsby';
import ShopifyBuy from 'shopify-buy';

import { buildStoreProductRouteFromItem } from 'utils/routes';
import { StoreContext } from 'context/StoreContext';

import styles from './LineItem.module.css';

enum QUANTITY_OPERATOR {
  ADD = 'ADD',
  SUBTRACT = 'SUBTRACT',
}

interface ILineItemProps {
  item: ShopifyBuy.LineItem;
}

export const LineItem: React.FC<ILineItemProps> = ({ item }) => {
  const {
    updateLineItem,
    removeLineItem,
    store: { client, checkout },
  } = useContext(StoreContext);

  const variantImage = item.variant.image ? (
    <img
      className={styles.itemImage}
      src={item.variant.image.src}
      alt={`${item.title} product shot`}
      height="180"
    />
  ) : null;

  const selectedOptions = item.variant.selectedOptions
    ? item.variant.selectedOptions.map(option => (
        <div className={styles.option} key={option.value}>
          {option.name}: {option.value}
        </div>
      ))
    : null;

  const handleUpdateItemQuantity = (operator: QUANTITY_OPERATOR) => {
    let newQuantity;

    switch (operator) {
      case QUANTITY_OPERATOR.ADD:
        newQuantity = item.quantity + 1;
        break;
      case QUANTITY_OPERATOR.SUBTRACT:
        newQuantity = item.quantity - 1;
        break;
      default:
        return;
    }

    updateLineItem(client, checkout.id, item.id, newQuantity);
  };

  const handleRemove = () => {
    removeLineItem(client, checkout.id, item.id);
  };

  const itemUrl = buildStoreProductRouteFromItem(item);

  return (
    <div className={styles.wrapper}>
      <div className="flex">
        <Link to={itemUrl} className={styles.itemImageWrapper}>
          {variantImage}
        </Link>
        <div className={styles.itemInfo}>
          <div>
            <Link to={itemUrl} className={styles.title}>
              {item.title}
            </Link>
            {item.variant.title !== 'Default Title' && (
              <p>{item.variant.title}</p>
            )}
            {selectedOptions}
          </div>
          <div className={styles.quantityContainer}>
            <div className={styles.quantityLabel}>Quantity:</div>
            <div className={styles.actionBar}>
              <div className={styles.quantityPickerContainer}>
                <button
                  className={styles.quantityPickerBtn}
                  onClick={() =>
                    handleUpdateItemQuantity(QUANTITY_OPERATOR.SUBTRACT)
                  }
                >
                  -
                </button>
                <span className={styles.quantityText}>{item.quantity}</span>
                <button
                  className={styles.quantityPickerBtn}
                  onClick={() =>
                    handleUpdateItemQuantity(QUANTITY_OPERATOR.ADD)
                  }
                >
                  +
                </button>
              </div>
              <div>
                <button
                  className={styles.deleteBtn}
                  type="button"
                  onClick={handleRemove}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.itemPrice}>
        <p className="ma0">Price: ${item.variant.price}</p>
      </div>
    </div>
  );
};
