import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { STORE_ROUTE } from 'utils/routes';

import { StoreContext, useStoreCartSize } from 'context/StoreContext';
import { LineItem } from './LineItem';
import { CartSubtotalBox } from './CartSubtotalBox';

import styles from './Cart.module.css';

export const Cart: React.FC = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext);
  const storeCartSize = useStoreCartSize();

  const lineItems = checkout.lineItems.map(item => (
    <LineItem key={item.id.toString()} item={item} />
  ));

  return (
    <div className={styles.page}>
      {lineItems.length ? (
        <div>
          <h1 className={styles.title}>Cart ({storeCartSize}) </h1>
          <div className={styles.columns}>
            <div className={styles.subtotalContainer}>
              <CartSubtotalBox />
            </div>
            <div className={styles.lineItems}>{lineItems}</div>
          </div>
        </div>
      ) : (
        <div className={styles.blankState}>
          <h1 className={styles.title}>Cart</h1>
          <p className={styles.blankStateSubtitle}>Your cart is empty.</p>
          <Link to={STORE_ROUTE} className={styles.btn}>
            Go to Store
          </Link>
        </div>
      )}
    </div>
  );
};
