import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import { StoreContext } from 'context/StoreContext';
import { getPrice, isDiscountCodeError } from 'utils/store';

import styles from './CartDiscount.module.css';

const getDiscountCopy = (discount: any) => {
  const isPercentageAmount = discount.value?.percentage;
  const discountAmount = isPercentageAmount
    ? `${discount.value.percentage}%`
    : getPrice({
        amount: discount.value.amount,
        currencyCode: discount.value.currencyCode,
      });
  const hasDiscountCode = discount.copy;

  if (hasDiscountCode) {
    return `${discount.code} - ${discountAmount}`;
  }

  return `Discounted - ${discountAmount}`;
};

const CartDiscountItem: React.FC<{ discount: any }> = ({ discount }) => {
  return (
    <span className={styles.discount}>
      <FontAwesomeIcon icon="tag" size="xs" />
      <span className={styles.discountCopy}>{getDiscountCopy(discount)}</span>
    </span>
  );
};

export const CartDiscount: React.FC = () => {
  const [showDiscount, setShowDiscount] = useState<boolean>(false);
  const [discountValue, setDiscountInput] = useState<string>('');
  const [errors, setErrors] = useState<any[]>([]);
  const {
    store: {
      checkout: { discountApplications: discounts, userErrors },
    },
    addDiscount,
    removeDiscount,
  } = useContext(StoreContext);

  const hasDiscounts = discounts.length > 0;

  // Ensures discount container cannot be hidden when discounts are applied
  useEffect(() => {
    if (hasDiscounts) {
      setShowDiscount(true);
    }
  }, [hasDiscounts]);

  // Ensure errors are cleared and propagated correctly
  useEffect(() => {
    if (userErrors?.length > 0) {
      const discountErrors = userErrors.filter(isDiscountCodeError);
      setErrors(discountErrors);
    }
    return () => setErrors([]);
  }, [userErrors]);

  /*
   * HANDLERS
   * ==========================================================
   */
  const clearDiscountInput = () => setDiscountInput('');

  const handleApplyDiscount = (e: React.FormEvent) => {
    e.preventDefault();

    setErrors([]);
    if (discountValue) {
      addDiscount(discountValue);
      clearDiscountInput();
    }

    return false;
  };

  const handleRemoveDiscount = () => {
    removeDiscount();
    clearDiscountInput();
  };

  const handleDiscountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountInput(e.target.value);
  };

  const handleToggleDiscountsDisplay = () => {
    if (!hasDiscounts) {
      setShowDiscount(s => !s);
    }
  };
  /* ==========================================================
   * END HANDLERS
   */

  return (
    <form action="#" className={styles.form} onSubmit={handleApplyDiscount}>
      <label
        htmlFor="discount-code"
        className={styles.label}
        onClick={handleToggleDiscountsDisplay}
      >
        <span>Discount:</span>
        {!hasDiscounts && (
          <span className={styles.arrow}>
            {showDiscount ? (
              <FontAwesomeIcon icon="chevron-up" size="sm" />
            ) : (
              <FontAwesomeIcon icon="chevron-down" size="sm" />
            )}
          </span>
        )}
      </label>

      <div
        className={cx(styles.discountContainer, {
          [styles.isHidden]: !showDiscount,
        })}
      >
        <div className={styles.inputContainer}>
          <input
            type="text"
            value={discountValue}
            onChange={handleDiscountChange}
            placeholder="Code"
            id="discount-code"
            className={styles.input}
          />
          <button
            className={styles.inputClearBtn}
            onClick={clearDiscountInput}
            type="button"
          >
            <FontAwesomeIcon icon="times" size="1x" />
          </button>
        </div>
        {errors && (
          <div className={styles.errors}>
            {errors.map((error, idx) => (
              <span className={styles.inputError} key={idx}>
                {error.message}
              </span>
            ))}
          </div>
        )}
        {hasDiscounts && (
          <div className={styles.discounts}>
            {discounts.map(discount => (
              <CartDiscountItem discount={discount} key={discount.code} />
            ))}
          </div>
        )}
        <div className={styles.actions}>
          <button type="submit" className={styles.addDiscountBtn}>
            Add Discount
          </button>

          {hasDiscounts && (
            <button
              type="button"
              className={styles.removeDiscountBtn}
              onClick={handleRemoveDiscount}
            >
              Remove Discounts
            </button>
          )}
        </div>
      </div>
    </form>
  );
};
