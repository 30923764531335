import React from 'react';

import Layout from 'components/layout';
import { Cart } from 'components/Store/Cart';
import { STORE_CART_SEO } from 'utils/seo';

const CartPage: React.FC = () => (
  <Layout {...STORE_CART_SEO}>
    <Cart />
  </Layout>
);

export default CartPage;
